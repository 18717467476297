.portfolio {
  margin: 0 auto 0;
  max-width: 1280px;
  width: 99.9%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #f6f6f6;
}

.portfolio__title {
  margin: 90px 0 60px 0;
  font-size: 34px;
  font-weight: 700;
}

.portfolio__link-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 25px;
  width: 99.5%;
}

.portfolio__link-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 625px;
}

.portfolio__image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.portfolio__image-link {
  position: relative;
  width: 100%;
  height: 100%;
}

.portfolio__image-link::before {
  width: 100%;
  height: 100%;
  content: '';
  background: #2a2c2f;
  position: absolute;
  opacity: 0;
  transition: 0.6s;
}

.portfolio__image-link:hover::before {
  opacity: 0.45;
}

.portfolio__figcaption {
  font-size: 18px;
  margin: 20px 0 10px 0;
  font-weight: 500;
  color: #000;
}

.portfolio__figcaption-link {
  list-style: none;
  color: #000;
}

.portfolio__figcaption-link:hover {
  opacity: 0.6;
  transition: 0.5s ease 0s;
}

@media screen and (max-width: 1024px) {
  .portfolio {
    max-width: 1023px;
  }

  .portfolio__link-container {
    gap: 20px;
  }
}

@media screen and (max-width: 900px) {
  .portfolio {
    max-width: 899px;
  }
}

@media screen and (max-width: 640px) {
  .portfolio {
    max-width: 375px;
  }

  .portfolio__title {
    margin: 80px 0 75px 0;
  }

  .portfolio__link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .portfolio__figcaption {
    margin: 32px 0 80px 0;
  }
}
