.app {
  background-color: #fff;
  margin: 0 auto 0;
  min-width: 320px;
  max-width: 1280px;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  font-family: 'gilroy', 'Helvetica Neue', 'Arial', 'sans-serif';
  /* outline: 3px solid black; */
}
