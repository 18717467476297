.footer {
  margin: 0 auto 0;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__title {
  margin: 115px auto 0;
  color: black;
  font-weight: 700;
  font-size: 34px;
}

.footer__text {
  margin: 20px auto 0;
  color: black;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  width: 275px;
  text-align: center;
}

.footer__button {
  background-color: black;
  border-radius: 515px;
  width: 180px;
  height: 42px;
  margin-top: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer__button:hover {
  opacity: 0.6;
  transition: 0.5s ease 0s;
}

.footer__button-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  color: #fff;
}

.footer__button-link {
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: black;
}

.footer__link-container {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  width: 342px;
  height: 40px;
}

.footer__link:hover {
  opacity: 0.8;
  transition: 0.5s ease 0s;
}

.footer__face-img {
  background-image: url('../../images/facebook-app-symbol.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__vk-img {
  background-image: url('../../images/vk-logo-of-social-network.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__inst-img {
  background-image: url('../../images/instagram-alternative.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__git-img {
  background-image: url('../../images/Git.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__link-container-text {
  margin: 40px 0 0 0;
  padding: 0;
  width: 243px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(130, 130, 130, 1);
}

.footer__link-container-text:last-child {
  margin: 0 0 0 0;
}

@media screen and (max-width: 900px) {
  .footer {
    max-width: 768px;
  }
}

@media screen and (max-width: 640px) {
  .footer {
    max-width: 375px;
    padding-bottom: 50px;
  }

  .footer__title {
    margin-top: 80px;
  }

  .footer__text {
    margin-top: 27px;
  }

  .footer__button {
    width: 316px;
    height: 42px;
    margin-top: 40px;
  }

  .footer__link-container {
    margin-top: 48px;
    width: 316px;
    height: 40px;
  }

  .footer__link-container-text {
    margin: 48px 0 12px 0;
    width: 315px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
