@font-face {
  font-family: 'gilroy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/gilroy-regular.woff2) format("woff2"),
       url(../fonts/gilroy-regular.woff) format("woff");
}
@font-face {
  font-family: 'gilroy';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/gilroy-medium.woff2) format("woff2"),
       url(../fonts/gilroy-medium.woff) format("woff");
}
@font-face {
  font-family: 'gilroy';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/gilroy-bold.woff2) format("woff2"),
       url(../fonts/gilroy-bold.woff) format("woff");
}
@font-face {
  font-family: 'gilroy';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/gilroy-black.woff2) format("woff2"),
       url(../fonts/gilroy-black.woff) format("woff");
}
