.hero {
  margin: 40px auto 0;
  max-width: 1280px;
  display: flex;
  align-content: center;
}

.hero__container {
  margin: 0 auto 0;
  width: 945px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
}

.hero__info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  outline: none;
}

.hero__name {
  margin: 0;
  width: 155px;
  font-size: 47px;
  line-height: 55px;
  font-weight: 700;
  color: #000;
  outline: none;
}

.hero__profi {
  margin: 0 40px 10px 0;
  width: 200px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #000;
  outline: none;
}

.hero__profi-ru {
  width: 226px;
}

.hero__container-lang {
  width: 77px;
  transform: rotate(-90deg);
  padding-top: 60px;
}

.hero__button-lang {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  font-size: 16px;
  color: #828282;
  font-weight: 700;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}

.hero__button-lang:hover {
  color: #090909;
  transition: color 0.5s;
}

.hero__button-lang_active {
  color: #000000;
}

.hero__foto {
  background-image: url('../../images/new-gorizont.png');
  background-position: center;
  background-size: cover;
  /* outline: 1px solid red; */
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 1024px) {
  .hero {
    max-width: 850px;
  }

  .hero__container {
    width: 850px;
    height: 600px;
  }

  .hero__name {
    width: 150px;
    font-size: 46px;
    line-height: 53px;
  }

  .hero__foto {
    width: 850px;
    height: 450px;
  }
}

@media screen and (max-width: 900px) {
  .hero {
    max-width: 899px;
  }

  .hero__container {
    width: 768px;
    height: 550px;
  }

  .hero__name {
    width: 145px;
    font-size: 45px;
    line-height: 52px;
  }

  .hero__foto {
    width: 768px;
    height: 410px;
  }
}

@media screen and (max-width: 820px) {
  .hero {
    max-width: 819px;
  }

  .hero__container {
    width: 600px;
    height: 450px;
  }

  .hero__name {
    width: 150px;
    font-size: 40px;
    line-height: 48px;
  }

  .hero__profi {
    margin: 0 0 5px 15px;
    width: 200px;
  }

  .hero__foto {
    width: 600px;
    height: 320px;
  }
}

@media screen and (max-width: 640px) {
  .hero {
    max-width: 375px;
    margin-top: 25px;
  }

  .hero__container {
    width: 319px;
    height: 688px;
  }

  .hero__info-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero__profi {
    margin: 23px 0 0 0;
    width: 200px;
  }

  .hero__container-lang {
    display: none;
  }

  .hero__foto {
    background-image: url('../../images/new-vert.png');
    width: 318px;
    height: 478px;
  }
}
