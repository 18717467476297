.navigation__button {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  right: 0;
  top: 32px;
  margin-left: 14px;
  border: none;
  text-decoration: none;
  width: 33px;
  height: 22px;
  background-color: transparent;
  list-style: none;
  cursor: pointer;
}

.navigation__button:hover {
  transform: rotate(180deg);
  transition: transform 0.2s;
}

.navigation__button_inactive {
  display: none;
}

.navigation__span {
  width: 33px;
  height: 5px;
  background-color: gray;
  padding: 0;
}

.navigation__span:nth-child(2) {
  margin-top: 3px;
}

.navigation__span:nth-child(3) {
  margin-top: 3px;
}

.navigation__span_menu-active:nth-child(1) {
  display: none;
}

.navigation__span_menu-active:nth-child(2) {
  width: 34px;
  transform: rotate(-48deg);
  transition: transform 0.35s;
}

.navigation__span_menu-active:nth-child(3) {
  width: 33px;
  margin-top: -5px;
  transform: rotate(42deg);
  transition: transform 0.35s;
}

.navigation__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  width: 321px;
  height: 760px;
  background: #fff;
  transform: translateX(100%);
  transition: transform 0.3s;
  visibility: hidden;
}

.navigation__menu_active {
  transform: translateX(0);
  transition: transform 0.75s;
  visibility: visible;
}

.navigation__nav-container {
  margin: 35px 0 0 0;
  padding: 0;
  height: 395px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigation__menu-link {
  width: 155px;
  font-size: 34px;
  line-height: 42px;
  color: #828282;
  cursor: pointer;
  text-decoration: none;
}

.navigation__menu-link:hover {
  color: #090909;
  transition: color 0.5s;
}

.navigation__menu-lang {
  width: 160px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transform: rotate(90deg);
  margin-left: 220px;
  margin-bottom: 102px;
}

.navigation__menu-button-lang {
  margin: 0;
  font-size: 32px;
  line-height: 42px;
  color: #828282;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}

.navigation__menu-button-lang:hover {
  color: #090909;
  transition: color 0.5s;
}

.navigation__menu-button-lang_active {
  color: #000000;
}

.navigation {
  margin: 0 auto 0;
  max-width: 1280px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.navigation_inactive {
  display: none;
}

.navigation__link-container {
  margin: 0 auto 0;
  width: 945px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(130, 130, 130, 1);
}

.navigation__link {
  font-weight: 700;
  font-size: 18px;
  color: #828282;
  text-decoration: none;
}

.navigation__link:hover {
  opacity: 0.6;
  transition: opacity 0.5s ease 0s;
}

.navigation__button-home {
  width: 92px;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: none;
  background-color: transparent;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .navigation__link-container {
    max-width: 850px;
  }
}

@media screen and (max-width: 900px) {
  .navigation__link-container {
    width: 768px;
  }
}

@media screen and (max-width: 820px) {
  .navigation__link-container {
    width: 600px;
  }
}
