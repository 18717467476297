.about {
  margin: 100px auto 0;
  max-width: 1280px;
  width: 99.9%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
}

.about__title {
  margin: 120px 0 0 0;
  padding: 0;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  color: #000;
}

.about__info-me-container {
  margin: 20px 0 110px 0;
  text-align: center;
  width: 430px;
}

.about__text {
  margin: 30px 0 0 0;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #000;
}

.about__text:first-child {
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .about {
    max-width: 1023px;
  }
}

@media screen and (max-width: 900px) {
  .about {
    max-width: 899px;
  }
}

@media screen and (max-width: 640px) {
  .about {
    margin-top: 80px;
    max-width: 375px;
    height: 491px;
  }
  .about__title {
    margin: 75px 0 0 0;
  }
  .about__info-me-container {
    margin-top: 30px;
    text-align: center;
    width: 316px;
    height: 261px;
  }
}
