.header {
  margin: 0 auto 0;
  max-width: 1280px;
  height: 90px;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 1024px) {
  .header {
    max-width: 870px;
    height: 85px;
  }
}

@media screen and (max-width: 900px) {
  .header {
    max-width: 768px;
    height: 75px;
  }
}

@media screen and (max-width: 640px) {
  .header {
    height: 0;
    width: 319.5px;
    position: relative;
  }
}
