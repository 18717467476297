@import url(./vendor/normalize.css);
@import url(./vendor/font/font.css);

.body {
  /* background-color: #fff; */
  /* outline: 4px solid red; */
  overflow-x: hidden;
  /* position: relative; */
  /* height:auto; */
}

#root {
  overflow: hidden;
}
