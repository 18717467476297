.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  height: 180px;
  width: 165px;
  /* outline: 1px solid red; */
}

.card__image-container {
  width: 88px;
  height: 89px;
}

.card__image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.card__image-stars-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* outline: 1px solid red; */
}

.card__image-stars {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.card__text {
  margin: 0;
  padding: 0;
  color: #828282;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .card {
    height: 155px;
    width: 134px;
  }

  .card__image-container {
    width: 75px;
    height: 76px;
  }
}

@media screen and (max-width: 900px) {
  .card {
    height: 105px;
    width: 94px;
  }

  .card__image-container {
    width: 47px;
    height: 48px;
  }
}

@media screen and (max-width: 640px) {
  .card {
    height: 170px;
    width: 206px;
  }

  .card__image-container {
    width: 89px;
    height: 88px;
  }

  .card__image-stars {
    /* margin-top: 40px; */
  }
}
